import {
  CloseCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Empty,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Steps,
  Tabs,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import PatientForm from "./PatientForm";
import "./index.css";
import AppointmentForm from "./AppointmentForm";
import MyDesktop from "../UtilComponents/MyDesktop";
import axios from "axios";
import TabPane from "antd/es/tabs/TabPane";
import { useNavigate } from "react-router-dom";
export default function AppointmentModal({ modalVisible, closeModal }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [patientDetails, setPatientDetails] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [patientLoading, setPatientLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const [activeTab, setActiveTab] = useState("1");

  const navigate = useNavigate();

  useEffect(() => {
    fetchDoctors({ roles: "4,7" });
  }, []);

  const handleButtonClick = () => {
    // Switch to the second tab when the button is clicked
    setActiveTab("2");
  };

  const handleClose = () => {
    setCurrentStep(0);
    closeModal();
    setSearch("");
    setSelectedPatient(false);
    setPatientList(false);
    setPatientDetails(null);
    setSelectedDoctor();
  };
  const handleSearchPatient = (params = {}) => {
    if (search?.length > 0) {
      setPatientLoading(true);
      axios
        .get(`/apiV1/patient-basic-info/`, { params: { ...params } })
        .then((res) => {
          setPatientList(res?.data?.result);
          setSearchText(search);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? "Something went wrong!"
          );
        })
        .finally(() => {
          setPatientLoading(false);
        });
    } else {
      message.error("Search cannot be empty");
    }
  };

  const fetchDoctors = (params = {}) => {
    axios
      .get(`apiV1/team/`, { params: { ...params } })
      .then((res) => {
        setDoctors(res?.data);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };
  const [doctorLoading, setDoctorLoading] = useState({ show: false, id: null });
  const handleSelectDoctor = (doctor) => {
    setDoctorLoading({
      show: true,
      id: doctor?.id,
    });
    axios
      .get(`apiV1/doctor-charge/`, {
        params: { doctor: doctor?.registration?.doctor },
      })
      .then((res) => {
        if (res?.data?.first_time_charge && res?.data?.returning_charge) {
          setSelectedDoctor({
            ...doctor,
            returning_expire_duration: res?.data?.returning_expire_duration,
            first_time_charge: res?.data?.first_time_charge,
            returning_charge: res?.data?.returning_charge,
          });
        } else {
          notification.error({
            message: (
              <div>
                <div>No charges defined for this doctor!</div>
                <Button
                  className="mt-3"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => {
                    closeModal();
                    navigate(`/config/doctor-profile/profession-fees`);
                  }}
                >
                  Add Fees
                </Button>
              </div>
            ),
          });
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setDoctorLoading({
          show: false,
          id: null,
        });
      });
  };
  return (
    <React.Fragment>
      <div className="">
        <Modal
          open={modalVisible}
          onCancel={() => {
            closeModal();
            setActiveTab("1");
            setSearch("");
          }}
          style={{
            top: "20px",
          }}
          closeIcon={
            <CloseCircleOutlined
              style={{ fontSize: "25px" }}
              onClick={handleClose}
            />
          }
          title={
            <div>
              <LeftOutlined
                className="mr-2"
                onClick={() => {
                  if (activeStep !== 1) {
                    if (activeStep === 2) {
                      setSelectedDoctor(null);
                      setSelectedPatient(null);
                    } else if (activeStep === 3) {
                      setSelectedDoctor(null);
                    }
                    setActiveStep((prev) => prev - 1);
                  }
                }}
              />
              Add Appointment
            </div>
          }
          footer={null}
          width={window.innerWidth > 700 ? 700 : window.innerWidth - 10}
          centered={false}
          className="th-full-screen-modal"
        >
          {selectedPatient ? (
            selectedDoctor ? (
              <div
                className="th-hidden-scrollbar"
                style={{
                  maxHeight: "490px",
                  overflowY: "scroll",
                }}
              >
                <AppointmentForm
                  patientDetails={patientDetails}
                  closeModal={handleClose}
                  selectedDoctor={selectedDoctor}
                />
              </div>
            ) : (
              <div>
                <div className="th-16 th-fw-500 mb-2">Select Doctor</div>
                {doctors?.length > 0 ? (
                  doctors?.map((each) => {
                    let avatar = each?.user?.name?.split(" ");
                    return (
                      <Row
                        className="mb-2 p-2 border th-br-5 th-pointer w-100"
                        onClick={() => {
                          handleSelectDoctor(each);
                          setActiveStep(3);
                        }}
                        justify={"space-between"}
                        align={"middle"}
                      >
                        <Space>
                          <Avatar className="mr-2" src={each?.user?.avatar}>
                            {!each?.user?.avatar &&
                              [
                                avatar[0]?.substring(0, 1)?.toUpperCase(),
                                avatar[avatar.length - 1]
                                  ?.substring(0, 1)
                                  ?.toUpperCase() ?? "",
                              ]?.join("")}
                          </Avatar>
                          <div className="">
                            <div className="">
                              Dr. {each?.registration?.name}
                            </div>
                            <div className="th-12">{each?.user?.contact}</div>
                          </div>
                        </Space>
                        {doctorLoading?.show &&
                        each?.id == doctorLoading?.id ? (
                          <LoadingOutlined
                            className="th-primary"
                            style={{ fontSize: "20px" }}
                          />
                        ) : (
                          <RightCircleFilled
                            className="th-primary"
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </Row>
                    );
                  })
                ) : (
                  <Empty description="No doctors in branch" />
                )}
              </div>
            )
          ) : (
            <Tabs
              tabBarGutter={16}
              activeKey={activeTab}
              onChange={setActiveTab}
            >
              <TabPane tab="Search Patient" key="1">
                <div className="">
                  <Input
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    value={search}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        handleSearchPatient({ search: search });
                      }
                    }}
                    maxLength={10}
                    placeholder="Search by contact/name"
                    suffix={
                      <Button
                        className=""
                        icon={<SearchOutlined />}
                        onClick={() => handleSearchPatient({ search: search })}
                        type="primary"
                        size="small"
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
                <div
                  className="mt-3 th-hidden-scrollbar"
                  style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                  {patientLoading ? (
                    <div className="d-flex justify-content-center my-5 w-100">
                      <Spin size="large" />
                    </div>
                  ) : patientList?.length > 0 ? (
                    patientList?.map((each) => (
                      <Row
                        className="mb-2 p-2 border th-br-5 th-pointer w-100"
                        onClick={() => {
                          setActiveStep(2);
                          setSelectedPatient(true);
                          setPatientDetails({
                            id: each?.id,
                            last_appointment: each?.last_appointment,
                          });
                        }}
                        justify={"space-between"}
                        align={"middle"}
                      >
                        <Space>
                          <Avatar className="mr-2">
                            {[
                              each?.first_name?.substring(0, 1)?.toUpperCase(),
                              each?.last_name?.substring(0, 1)?.toUpperCase() ??
                                "",
                            ]?.join("")}
                          </Avatar>
                          <div className="">
                            <div className="">
                              {each?.first_name} {each?.middle_name}{" "}
                              {each?.last_name} (
                              {each?.gender?.substring(0, 1)?.toUpperCase()}){" "}
                            </div>
                            <div className="">{each?.contact}</div>
                          </div>
                        </Space>
                        <RightCircleFilled
                          className="th-primary"
                          style={{ fontSize: "20px" }}
                        />
                      </Row>
                    ))
                  ) : (
                    <div className="mx-auto mt-4">
                      <Empty description="No Patients" />
                      <div className="text-center">
                        <Button
                          className="mt-2 mx-auto"
                          onClick={handleButtonClick}
                          type="primary"
                          size="small"
                        >
                          Create New
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Create New" key="2">
                <div className="py-3">
                  <MyDesktop>
                    <Steps
                      current={currentStep}
                      items={[
                        {
                          title: "Patient Form",
                        },
                        {
                          title: "Appointment Details",
                        },
                      ]}
                    />
                  </MyDesktop>

                  <div className="mt-2">
                    {currentStep === 0 && (
                      <PatientForm
                        // handleNext={() => {
                        //   setCurrentStep("1");
                        // }}
                        setPatientDetails={(value) => {
                          setSelectedPatient(true);
                          setPatientDetails(value);
                        }}
                        contact={search}
                      />
                    )}
                    {currentStep == 1 && (
                      <AppointmentForm
                        patientDetails={patientDetails}
                        closeModal={handleClose}
                      />
                    )}
                  </div>
                </div>
              </TabPane>
            </Tabs>
          )}
        </Modal>
        {/* <Modal
          open={selectedPatient}
          onCancel={() => {
            setSelectedPatient(false);
          }}
          style={{
            height: "400px",
            overflowY: "scroll",
          }}
          closeIcon={<CloseCircleOutlined style={{ fontSize: "25px" }} />}
          title="Patient List"
          footer={null}
          width={window.innerWidth > 700 ? 700 : window.innerWidth - 10}
          centered={true}
        >
          <AppointmentForm
            patientDetails={patientDetails}
            closeModal={handleClose}
          />
        </Modal> */}
      </div>
    </React.Fragment>
  );
}
