import { RightOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useRef, useState } from "react";
import { useData } from "../../../../context/data-context";
import { useAuth } from "../../../../context/auth-context";
import axios from "axios";

export default function PatientForm({
  // handleNext,
  setPatientDetails,
  contact,
}) {
  const formRef = useRef();
  const [avatar, setAvatar] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataValue = useData();
  const authData = useAuth();
  const storedData = dataValue?.dataState;
  const currUserData = authData?.currUser;
  const [medicalHistoryInput, setMedicalHistoryInput] = useState("");
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setAvatar(file);
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
    let imgType = event.target?.files[0]?.type;
    if (allowedExtension.indexOf(imgType) === -1) {
      message.error("Only image(.jpeg, .jpg, .png) is acceptable!");
      return;
    }
    setSelectedImage(URL.createObjectURL(file));
  };
  const onSubmit = (values) => {
    const { selectedBranch } = storedData;
    setLoading(true);
    const payload = {
      ...values,
      medical_history: medicalHistory,
      branch: selectedBranch,
    };
    var formData = new FormData();

    for (var key in payload) {
      if (!!payload[key]) {
        formData.append(key, payload[key]);
      }
    }
    if (avatar) {
      formData.append("avatar", avatar, avatar.name);
    }
    // formData.append("user", currUserData?.id);
    axios
      .post("/apiV1/patient/", formData)
      .then((res) => {
        setPatientDetails(res?.data);
        formRef.current.resetFields();
        // handleNext();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
        console.log(err, "err");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Form
        onFinish={onSubmit}
        ref={formRef}
        id="patientForm"
        layout="vertical"
        initialValues={{
          contact: contact,
        }}
      >
        <Row gutter={24}>
          <Col className="py-2" md={24}>
            <Form.Item label={"Patient Image"}>
              <div className="d-flex align-items-end">
                {selectedImage ? (
                  <Avatar shape="square" src={selectedImage} size={80} />
                ) : (
                  <Avatar shape="square" icon={<UserOutlined />} size={80} />
                )}

                {!selectedImage ? (
                  <div className="pl-3">
                    <div className="pb-1">No file uploaded</div>
                    <label
                      style={{ marginBottom: "0px" }}
                      htmlFor="profile"
                      role="button"
                      className="ant-btn ant-btn-primary px-3 py-1 text-white th-br-5"
                    >
                      Upload Image
                    </label>
                    <input
                      id="profile"
                      className="d-none"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handlePhotoChange}
                    />
                  </div>
                ) : (
                  <div className="pl-3">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedImage(null);
                      }}
                      type="primary"
                    >
                      Remove Image
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"first_name"}
              label={"First Name"}
              rules={[
                { required: true, message: "First name is required!" },
                {
                  pattern: /^[A-Za-z]+$/,
                  message: "Only alphabets are allowed!",
                },
              ]}
            >
              <Input className="w-100" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"middle_name"}
              label={"Middle Name"}
              rules={[
                {
                  pattern: /^[A-Za-z]+$/,
                  message: "Only alphabets are allowed!",
                },
              ]}
            >
              <Input className="w-100" placeholder="Middle Name" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"last_name"}
              label={"Last Name"}
              rules={[
                { required: true, message: "Last name is required!" },
                {
                  pattern: /^[A-Za-z]+$/,
                  message: "Only alphabets are allowed!",
                },
              ]}
            >
              <Input className="w-100" placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"age"}
              label={"Age"}
              rules={[{ required: true, message: "Age is required!" }]}
            >
              <InputNumber min={1} className="w-100" placeholder="Age" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"gender"}
              label={"Gender"}
              rules={[{ required: true, message: "Gender is required!" }]}
            >
              <Select className="w-100" placeholder={"Gender"}>
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="others">Prefer not to say</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Blood Group" name="blood_group">
              <Select allowClear placeholder="Blood Group" className="w-100">
                <Select.Option value="a+">A+</Select.Option>
                <Select.Option value="a-">A-</Select.Option>
                <Select.Option value="b+">B+</Select.Option>
                <Select.Option value="b-">B-</Select.Option>
                <Select.Option value="ab+">AB+</Select.Option>
                <Select.Option value="ab-">AB-</Select.Option>
                <Select.Option value="o+">O+</Select.Option>
                <Select.Option value="o-">O-</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"contact"}
              label={"Whatsapp Number"}
              rules={[
                { required: true, message: "Whatsapp number is required!" },
                { pattern: /^\d{10}$/, message: "Must be exactly 10 digits!" },
              ]}
            >
              <Input className="w-100" placeholder="Whatsapp number" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name={"email"}
              label={"Email Address"}
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input className="w-100" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Referred By" name="referred_by">
              <Input className="w-100" placeholder="Referred By" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Primary Language">
              <Select>
                <Select.Option value="hindi">Hindi</Select.Option>
                <Select.Option value="english">English</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              rules={[
                { required: true, message: "City is required!" },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Only alphabets are allowed!",
                },
              ]}
              label="City"
              name="city"
            >
              <Input className="w-100" placeholder="City" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  max: 200,
                  message: "Address cannot exceed 200 characters!",
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-end pt-3">
        <Button
          form="patientForm"
          loading={loading}
          htmlType="submit"
          type="primary"
        >
          Next <RightOutlined />
        </Button>
      </div>
    </React.Fragment>
  );
}
