import React, { useEffect, useRef, useState } from "react";
import { useData } from "../../../../context/data-context";
import { useAuth } from "../../../../context/auth-context";
import dayjs from "dayjs";
import axios from "axios";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  DownOutlined,
  SyncOutlined,
  UpOutlined,
} from "@ant-design/icons";
import ExplicitEqueueModal from "../../ExplicitEqueueModal";

export default function AppointmentForm({
  patientDetails,
  closeModal,
  contact,
  selectedDoctor,
}) {
  const formRef = useRef();
  const dataValue = useData();
  const authData = useAuth();
  const storedData = dataValue?.dataState;
  const [loading, setLoading] = useState(false);
  const [equeueData, setEqueueData] = useState([]);
  const [equeueLoading, setEqueueLoading] = useState(false);
  const [selectedEqueue, setSelectedEqueue] = useState();
  const { selectedBranch, selectedDoctorDetails, appointmentCounter } =
    storedData;
  const [showAll, setShowAll] = useState(false);
  const [returningExpired, setReturningExpired] = useState(false);
  const [showEqueueModal, setShowEqueueModal] = useState(false);

  useEffect(() => {
    if (selectedDoctor) {
      const patientLastAppointment = patientDetails?.last_appointment;
      if (patientLastAppointment) {
        const isExpired =
          dayjs().diff(dayjs(patientLastAppointment), "day") >
          selectedDoctor?.returning_expire_duration;
        setReturningExpired(isExpired);
      }
      fetchEqueueInfo({
        doctor: selectedDoctor?.registration?.doctor,
        branch: selectedBranch,
      });
    }
  }, [selectedDoctor]);
  const fetchEqueueInfo = (params = {}) => {
    setEqueueLoading(true);
    axios
      .get(`/apiV1/e-queue/`, { params: { ...params } })
      .then((res) => {
        setEqueueData(res?.data);
        setSelectedEqueue(res?.data[0]?.id);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setEqueueLoading(false);
      });
  };

  const onSubmit = (values) => {
    setLoading(true);
    let payload = {
      doctor: selectedDoctor?.registration?.doctor,
      slot: null,
      is_first_visit: values?.is_first_visit,
      reason: values?.reason,
      is_equeue: true,
      amount: returningExpired
        ? selectedDoctor?.first_time_charge
        : values?.is_first_visit
        ? selectedDoctor?.first_time_charge
        : selectedDoctor?.returning_charge,
      branch: selectedBranch,
      payment_method: "cash",
      date: equeueData?.find((i) => i.id == selectedEqueue)?.date,
      appointment_date: equeueData?.find((i) => i.id == selectedEqueue)?.date,
      patient: patientDetails?.id,
      is_clinic_booking: true,
    };
    axios
      .post(`apiV1/appointment/`, payload)
      .then(() => {
        message.success("Appointment Booked Successfully!");
        formRef.current.resetFields();
        closeModal();
        const newAppointmentCounter = appointmentCounter + 1;
        dataValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "appointmentCounter",
            value: newAppointmentCounter,
          },
        });
      })
      .catch((err) => {
        console.log({ err });
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const displayedData = showAll ? equeueData : equeueData.slice(0, 2);
  return (
    <React.Fragment>
      <Row
        className="mb-2 p-2 border th-br-5 th-pointer w-100 "
        justify={"space-between"}
        align={"middle"}
        style={{
          background: "#F0F5FF",
          border: "1px solid #3F96FE !important",
        }}
      >
        <Space>
          <Avatar className="mr-2" src={selectedDoctor?.user?.avatar}>
            {!selectedDoctor?.user?.avatar &&
              [
                selectedDoctor?.user?.name
                  ?.split(" ")[0]
                  ?.substring(0, 1)
                  ?.toUpperCase(),
                selectedDoctor?.user?.name
                  ?.split(" ")[1]
                  ?.substring(0, 1)
                  ?.toUpperCase() ?? "",
              ]?.join("")}
          </Avatar>
          <div className="">
            <div className="">Dr. {selectedDoctor?.registration?.name}</div>
            <div className="th-12">{selectedDoctor?.user?.contact}</div>
          </div>
        </Space>
      </Row>
      {returningExpired && (
        <div className="text-danger">
          *Note :{" "}
          <i>
            The returning consultation period has expired, and the consultation
            fee will now be the same as the initial consultation fee.
          </i>
        </div>
      )}
      <Form
        onFinish={onSubmit}
        ref={formRef}
        id="appointmentForm"
        layout="vertical"
        className="mt-4"
      >
        <Row gutter={24}>
          <Col md={12}>
            <Form.Item
              label="What’s The Reason for Your Visit?"
              name="reason"
              rules={[{ message: "Reason is required!" }]}
            >
              <Select placeholder="Reason for visit" className="w-100">
                <Select.Option value="general_consultation">
                  General Consultation
                </Select.Option>
                <Select.Option value="follow_up">Follow Up</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Have the patient seen these Doctors?"
              name={"is_first_visit"}
              required
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}>First Time</Radio>
                <Radio value={false}>Returning</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={24}>
            <Space>
              <h5>Select Slot</h5>
              <Button
                size="small"
                type="primary"
                shape="circle"
                onClick={() => {
                  fetchEqueueInfo({
                    doctor: selectedDoctor?.registration?.doctor,
                    branch: selectedBranch,
                  });
                }}
                icon={<SyncOutlined spin={equeueLoading} />}
              />
            </Space>
            <div className="mt-1 col-md-12">
              {equeueLoading ? (
                <Skeleton className="mt-3" />
              ) : equeueData?.length > 0 ? (
                <>
                  <Row gutter={24}>
                    {displayedData.map((item, index) => {
                      const isToday = dayjs(item?.date).isSame(dayjs(), "day");
                      return (
                        <Col
                          key={index}
                          md={12}
                          className="p-1 mb-1"
                          onClick={() => setSelectedEqueue(item?.id)}
                        >
                          <div
                            style={{
                              background:
                                selectedEqueue === item?.id
                                  ? "#F7FFF6"
                                  : "#fff",
                              borderLeft:
                                selectedEqueue === item?.id &&
                                "4px solid #01A400",
                            }}
                            className="card p-2 shadow-sm"
                          >
                            <div className="th-16">
                              {isToday
                                ? "Today"
                                : dayjs(item?.date).format("Do MMM, YYYY")}
                            </div>
                            <hr className="m-0" />
                            <div className="mt-2 d-flex align-items-center gap-2">
                              <div className="py-1 px-3 th-26 th-fw-600 card shadow-sm text-danger th-br-10">
                                {item?.current_equeue + 1}
                              </div>
                              <div className="ml-2">
                                <div className="th-fw-400">Waiting Number</div>
                                <div className="th-18 th-primary th-fw-500">
                                  {}
                                  {isToday &&
                                  dayjs().isAfter(
                                    dayjs(item?.expected_time, "HH:mm:ss")
                                  )
                                    ? dayjs().format("hh:mm A")
                                    : dayjs(
                                        item?.expected_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <p className="th-12 m-0">
                                *Given Time is approximate can vary by +/-{" "}
                                {item?.average_time} Min
                              </p>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  {equeueData.length > 2 && (
                    <div className="text-center mt-2">
                      <Button
                        icon={showAll ? <UpOutlined /> : <DownOutlined />}
                        onClick={() => setShowAll(!showAll)}
                      >
                        {showAll ? "Show Less" : "Show More"}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <div className="mx-auto mt-2">
                  <Empty description="E-Queues not available for this clinic!" />
                  <div className="th-12 text-center">
                    Appointment cannot be booked until e-Queue is not created!
                  </div>
                  <div className="text-center mt-2">
                    <Button
                      onClick={() => {
                        setShowEqueueModal(true);
                      }}
                      type="primary"
                    >
                      Create e-Queue
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Form>
      {equeueData?.length > 0 && (
        <div className="d-flex justify-content-end">
          <Button
            form="appointmentForm"
            loading={loading}
            htmlType="submit"
            type="primary"
          >
            Submit
          </Button>
        </div>
      )}
      <ExplicitEqueueModal
        open={showEqueueModal}
        onCloseModal={() => {
          setShowEqueueModal(false);
        }}
        preSelectedDoctor={selectedDoctor?.registration?.doctor}
        callbackFunc={() => {
          fetchEqueueInfo({
            doctor: selectedDoctor?.registration?.doctor,
            branch: selectedBranch,
          });
        }}
      />
    </React.Fragment>
  );
}
